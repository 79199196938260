<template>
  <div>
    <div class="mains" v-if="loading" v-loading="loading" element-loading-spinner="el-icon-loading"></div>
    <div v-else>
      <div class="man-title">
        <span>孪生体管理</span>
        <!-- <div class="btn">
          <el-button type="primary" size="mini" @click="openBox">导入孪生体</el-button>
        </div> -->
      </div>
      <div class="table-tree">
        <div class="tree" v-loading="treeLoading" element-loading-spinner="el-icon-loading">
          <div class="tree-title"><i class="el-icon-share" style="margin-right:10px;"></i>孪生体分类</div>
          <div v-if="parkData.length>0">
            <div class="tree-park" v-for="(item,index) in parkData" :key="index">
              <div class="project-title" @click.prevent="parkToggle(item)">
                <span class="icon-triangle" :class="item.disable?'arrow':'unarrow'"></span>
                <span>{{item.projectName}} ({{item.parkDataList.length}})</span>
              </div>
              <div v-if="item.disable">
                <div class="tree-park" v-for="(val,key) in item.parkDataList" :key="key">
                  <div class="park-title" @click.prevent="parkToggle(val)">
                    <span class="icon-triangle" :class="val.disable?'arrow':'unarrow'"></span>
                    <span>{{val.parkName}} ({{val.typeDataList.length}})</span>
                  </div>
                  <div class="tree-classify" v-if="val.disable">
                    <div v-for="(tItem,tIndex) in val.typeDataList" :key="tIndex">
                      <div class="classify-title" :class="current==(val.parkName+tItem.typeId)?'bright':''" @click="selectClassify(item, val, tItem)">
                        <span class="class-icon iconfont icon-hezi" :style="current==(val.parkName+tItem.typeId)?'color:#FFF;':'color:#3498db;'"></span>
                        <span>{{tItem.type}}</span>
                      </div>
                    </div>
                    <div class="classify-title" :class="current1==(val.parkName+'default')?'bright':''"  @click="selectDefaultClassify(item, val)">
                      <span class="class-icon iconfont icon-hezi" :style="current1==(val.parkName+'default')?'color:#FFF;':'color:#fad390;'"></span>
                      <span>未分类</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else style="width: 100%;text-align: center;line-height: 60px;font-size: 14px;color: #909399;">暂无数据</div>
        </div>
        <div class="table">
          <el-table :data="tableData" style="width: 100%" v-loading="tableLoading" element-loading-spinner="el-icon-loading">
            <el-table-column label="序号" type="index" width="50"></el-table-column>
            <el-table-column prop="park" label="分类名称"></el-table-column>
            <el-table-column label="孪生体编号">
              <template slot-scope="scope">
                {{ scope.row.digitalTwinId }}
              </template>
            </el-table-column>
            <el-table-column label="孪生体名称">
              <template slot-scope="scope">
                {{ scope.row.digitalTwinName }}
              </template>
            </el-table-column>
            <el-table-column label="绑定设备">
              <template slot-scope="scope">
                <el-tag type="warning" v-if="scope.row.bindEquipment==0">未绑定</el-tag>
                <el-tag type="success" v-if="scope.row.bindEquipment==1">已绑定</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间">
              <template slot-scope="scope">
                {{ scope.row.createTime ? dayjs(scope.row.createTime).format('YYYY-MM-DD HH:mm:ss'):'' }}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="180">
              <template slot-scope="scope">
                <el-dropdown placement="bottom-start" trigger="click">
                  <span @click.stop="">
                    <span class="el-dropdown-link">
                      操作<i class="el-icon-arrow-down"></i>
                    </span>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-if="scope.row.bindEquipment==0"><div @click="bindDevice(scope.row)">绑定</div></el-dropdown-item>
                    <el-dropdown-item v-if="scope.row.bindEquipment==1"><div @click="unBindDevice(scope.row)">解绑</div></el-dropdown-item>
                    <el-dropdown-item><div @click="detailDevice(scope.row)">详情</div></el-dropdown-item>
                    <el-dropdown-item><div @click="editDevice(scope.row)">编辑</div></el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
          <!-- <page v-if="total!==0" :size="size" :page="page" :total="total" v-on:sizeChange="sizeChange" v-on:pageChange="pageChange"></page> -->
        </div>
      </div>
      <el-dialog title="导入孪生体" :visible.sync="dialogFormVisible" width="25%" top="30vh">
        <div class="import-park">
          <span class="park-required">*</span>
          <span class="park-name">项目名称</span>
        </div>
        <el-input v-model="parkName" autocomplete="off"></el-input>
        <div slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogFormVisible = false">取 消</el-button>
          <el-upload
            class="cancel-btn"
            v-show="isShow"
            action=""
            :auto-upload="false"
            accept=".xlsx, .xls"
            :show-file-list="false"
            :on-change="handleChange">
            <el-button size="small" type="primary">导 入</el-button>
          </el-upload>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import page from '@/components/page.vue'
import dayjs from 'dayjs';
export default {
  components: {
    page
  },
  data() {
    return {
      loading: false,
      treeLoading: false,
      tableLoading: false,
      size: 50,
      page: 1,
      total: 0,
      parkData: [],//项目数据

      tableData: [],//孪生体数数据
      current: null,
      current1: null,
      park: null,
      typeId: null,
      parkName: null,
      dialogFormVisible: false,
      isShow: false,
      staffId: null
    };
  },
  watch:{
    parkName(newv,oldv) {
      if(newv) {
        this.isShow = true
      }else {
        this.isShow = false
      }
    }
  },
  methods: {
    dayjs,
    openBox() {
      this.dialogFormVisible = true
      this.isShow = false
      this.parkName = null
    },
    handleChange(e) {
      console.log(e)
      let formData = new FormData();
      formData.append("park", this.parkName);
      formData.append("file", e.raw);
      
      this.loading = true
      this.$ajax.post("importTwins", formData).then((res) => {
        this.loading = false
        this.$message.success("导入成功");
        this.loadListData()
      }).catch(err=>{
        this.loading = false
      })
      this.dialogFormVisible = false
    },
    edit(row) {
      this.$router.push({ name: 'deviceListEdit', params: {
        row: row,
        bumen: this.bumen,
        leixin: this.leixin,
        yuangong: this.yuangong
      }})
    },
    bindDevice(row) {
      this.$router.push({ name: 'iotTwinsManageBind', params: {row: row}})
    },
    unBindDevice(row) {
      this.$confirm(`确认解绑孪生体: [ ${row.digitalTwinName} ] ?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$ajax.post("iotUnbindTwinsData", {
          digitalTwinId: row.digitalTwinId,
          eid: row.eid
        }).then((res) => {
          this.loadListData();
        });
      });
    },
    detailDevice(row) {
      this.$router.push({ name: 'iotTwinsManageDetail', params: {row: row}})
    },
    editDevice(row) {
      this.$router.push({ name: 'iotTwinsManageEdit', params: {row: row}})
    },
    // 展开折叠分类
    parkToggle(item) {
      item.disable = !item.disable
    },

    // 选择指定分类
    selectClassify(item, val, tItem) {
      this.current1 = null
      this.companyId = item.companyId
      this.projectId = item.projectId
      this.park = val.parkName
      this.typeId = tItem.typeId
      this.current = val.parkName+tItem.typeId
      this.loadListData()
    },

    // 选择未分类
    selectDefaultClassify(item, val) {
      this.current = null
      this.companyId = item.companyId
      this.projectId = item.projectId
      this.park = val.parkName
      this.typeId = 'default'
      this.current1 = val.parkName+'default'
      this.loadListData()
    },

    // 获取项目列表
    loadProjectList() {
      this.treeLoading = true
      this.$ajax.post('iotUinoProjectQuery').then(res => {
        if(res.data.length==0) {
          this.treeLoading = false
        }
        res.data.forEach(v => {
          v.disable = false
          this.loadParkList(v)
        });
      }).catch(err=>{
        this.treeLoading = false
      })
    },

    // 获取园区列表
    loadParkList(projectObj) {
      this.$ajax.post('iotUinoClassifQuery', {
        companyId: projectObj.companyId,
        staffId: this.staffId,
        projectId: projectObj.projectId
      }).then(res => {
        if(res.data.length==0) {
          this.treeLoading = false
        }
        let arr = []
        res.data.forEach(v => {
          arr.push({
            parkId: v,
            parkName: v,
            disable: false,
          })
          projectObj.parkDataList = arr
        });
        this.loadClassifyList(projectObj,projectObj.parkDataList)
      }).catch(err=>{
        this.treeLoading = false
      })
    },
    // 获取孪生体分类
    loadClassifyList(projectObj,parkDataList) {
      this.$ajax.post('uinoDigitalTwinTypeQuery', {
        projectId: projectObj.projectId
      }).then(res => {
        parkDataList.forEach(v=>{
          v.typeDataList = res.data
        })
        this.parkData.push(projectObj)
        this.treeLoading = false
      }).catch(err=>{
        this.treeLoading = false
      })
    },
    
    // 获取孪生体数据
    loadListData() {
      this.tableLoading = true
      this.$ajax.post('iotTwinsQuery', {
        companyId: this.companyId,
        staffId: this.staffId,
        projectId: this.projectId,
        park: this.park,
        typeId: this.typeId
      }).then(res => {
        this.tableData = res.data
        this.total = res.total
        this.tableLoading = false
      }).catch(err=>{
        this.tableLoading = false
      })
    },
    pageChange(val) {
      this.page = val
      sessionStorage.setItem('currentPage', val)
      this.loadListData()
    },
    sizeChange(val) {
      this.size = val
      sessionStorage.setItem('currentSize', val)
      this.loadListData()
    },
    getLastPage() {
      this.page = Number(sessionStorage.getItem('currentPage')) || 1
    },
    getSize() {
      this.size = Number(sessionStorage.getItem('currentSize')) || 50
    },
  },
  created() {
    this.staffId = sessionStorage.getItem('id')
    this.getLastPage()
    this.getSize()
    this.loadProjectList()
  },
  mounted() {
    
  },
  destroyed() {
    
  }
};
</script>

<style lang="less" scoped>
.mains {
  padding: 400px 0;
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.table-tree {
  display: flex;
  height: ~'calc(100% - 56px)';
  .tree {
    width: 300px;
    border-right: 4px solid #f6f7fb;
    margin-right: 20px;
  }
  .table {
    flex: 1;
  }
  .operate {
    &:hover {
      color: #409EFF;
    }
  }
  .custom-tree-node {
    font-size: 14px;
  }
}
.btn {
  float: right;
}
.search {
  padding: 0 10px;
}
.tree-park {
  font-size: 14px;
  cursor: pointer;
  user-select: none;
}
.project-title {
  line-height: 35px;
  padding-left: 20px;
  transition: all .4s ease;
}
.project-title:hover {
  background: #f6f7fb;
}
.park-title {
  line-height: 35px;
  padding-left: 35px;
  transition: all .4s ease;
}
.icon-triangle {
  margin-right: 5px;
  transition: all .4s ease;
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid #ccc;
  border-top: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-radius: 3px;
}
.arrow {
  transform: rotate(90deg);
}
.unarrow {
  transform: rotate(0deg);
}
.park-title:hover {
  background: #f6f7fb;
}
.tree-classify {
  line-height: 35px;
}
.classify-title {
  padding-left: 50px;
}
.class-icon {
  display: inline-block;
  margin-right: 8px;
}
.bright {
  background: #409EFF;
  color: #FFF;
}
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
.cancel-btn {
  margin-left: 20px;
}
.import-park {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.park-required {
  color: red;
  margin-right: 3px;
}
</style>